import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({

  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],

  state: {
    viewComponents: true,
    datosUsuariosState: {},
    tokenState: null,
    loggedInState:false
  },
  mutations: {
    LOGGED_IN_MUTATION(state) {
      state.loggedInState = true;
    },
    VIEW_COMPONENETS_MUTATION(state, update) {
      state.viewComponents = update;
    },
    TOKEN_MUTATION(state, token) {
      state.tokenState = token;
    },
    DATOS_USUARIOS_MUTATION(state, datos) {
      state.datosUsuariosState = datos;
    },
  },
  actions: {},
  modules: {},
});
