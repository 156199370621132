<template>
  <div>
    <v-navigation-drawer v-model="drawer" fixed app temporary>
      <v-list dense>
        <v-list-item-group v-for="(item, i) in items" :key="i" color="primary">
          <v-list-item v-if="!item.submenu" :to="item.to">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title.toUpperCase()" />
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-else :prepend-icon="item.icon" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title.toUpperCase()"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="child in item.submenu" :key="child.title" :to="child.to">
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar fixed app hide-on-scroll height="64" elevate-on-scroll>
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = true" />
      <router-link to="/" class="d-flex">
        <logo />
      </router-link>
      <v-spacer />

      <template v-for="(name, menuitem) in items">
        <template v-if="name.submenu">
          <v-menu
            :key="menuitem"
            open-on-hover
            offset-y
            transition="slide-y-transition"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                plain
                class="py-8 submenubtn hidden-sm-and-down"
                :ripple="false"
                v-bind="attrs"
                v-on="on"
              >
                {{ name.title }}
                <v-icon right small class="mx-0"> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(item, index) in name.submenu"
                :key="index"
                link
                :to="item.to"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <v-btn
          v-else
          :key="menuitem"
          depressed
          tile
          plain
          class="py-8 hidden-sm-and-down"
          :to="name.to"
          >{{ name.title }}</v-btn
        > </template
      ><v-spacer />
      <v-btn icon @click="changeThemeColor">
        <v-icon>{{
          $vuetify.theme.dark ? "mdi-white-balance-sunny" : "mdi-weather-night"
        }}</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import logo from "@/components/Logo";

export default {
  components: {
    logo,
  },
  data() {
    return {
      clipped: false,
      drawer: false,
      items: [
        {
          icon: "mdi-home",
          title: "Home",
          to: "/",
        },
        {
          icon: "mdi-human-male-female",
          title: "Quiénes somos",
          to: "/about",
        },
        {
          icon: "mdi-web",
          title: "Soluciones",
          submenu: [
            {
              title: "Capacitación",
              to: "/services",
            },
            {
              title: "Materia Jurídica",
              to: "/servicesJuridico",
            },
            {
              title: "Sector empresarial",
              to: "/servicesEmpresarial",
            },
            {
              title: "Insignia LAT",
              to: "/servicesLat",
            },
            {
              title: "Business School",
              to: "/servicesSchool",
            },
            {
              title: "Diseño y desarrollo de software",
              to: "/servicesSoftware",
            },
          ],
        },
        // {
        //   icon: 'mdi-cart',
        //   title: 'Precios',
        //   to: '/pricing',
        // },
        // {
        //   icon: 'mdi-camera-iris',
        //   title: 'Gallery',
        //   to: '/gallery',
        // },
        {
          icon: "mdi-contacts",
          title: "Contacto",
          to: "/contact",
        },
         {
          icon: "mdi-contacts",
          title: "Inscríbete",
          to: "/inscribirse",
        },
      ],
    };
  },
  methods: {
    changeThemeColor() {
      if (this.$vuetify.theme.dark === true) {
        this.$vuetify.theme.dark = false;
      } else {
        this.$vuetify.theme.dark = true;
      }
    },
  },
};
</script>

<style scoped>
.submenubtn {
  cursor: default;
}
</style>
