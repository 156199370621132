<template>
  <v-app dark>
    <siteHeader v-if="viewComponents" />
    <v-main>
      <router-view />
      <footerTop v-if="viewComponents" />
    </v-main>
    <siteFooter v-if="viewComponents" />
  </v-app>
</template>

<script>
import siteHeader from "@/components/siteHeader.vue";
import footerTop from "@/components/footerTop.vue";
import siteFooter from "@/components/siteFooter.vue";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    siteHeader,
    footerTop,
    siteFooter,
  },
  computed: {
    ...mapState(["viewComponents"]),
  },
  methods: {
    ...mapMutations(["VIEW_COMPONENETS_MUTATION"]),
  },
  head() {
    return {
      script: [
        {
          src: "https://www.googletagmanager.com/gtag/js?id=G-0DYDMZEYDZ",
        },
      ],
    };
  },

  watch: {
    $route() {
      if (this.$route.path !== "/loginAdmin") {
        this.VIEW_COMPONENETS_MUTATION(true);
      }
    },
  },
};
</script>

<style>
/* Las animaciones de entrada y salida pueden usar */
/* funciones de espera y duración diferentes.      */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
