<template>
  <section
    id="footer-top"
    :class="
      $vuetify.theme.dark
        ? 'blue-grey darken-4 grey--text text--lighten-1'
        : 'grey lighten-3 grey--text text--darken-4'
    "
  >
    <v-container>
      <v-row>
        <v-col cols="12" md="5" class="py-12"
          ><v-row no-gutters>
            <v-col cols="12">
              <logo />
            </v-col>
            <v-col cols="12">
              <v-list two-line class="transparent">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-map-marker </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>DIRECCIÓN</v-list-item-title>
                    <v-list-item-subtitle
                      >Av. Concordia, Ciudad del Carmen, Campeche</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-email </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>CORREO</v-list-item-title>
                    <v-list-item-subtitle>ejimenez@ejpartners.com</v-list-item-subtitle>
                    <v-list-item-subtitle>imay@ejpartners.com</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-phone </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>TELÉFONO</v-list-item-title>
                    <v-list-item-subtitle>(938) 192 86 81</v-list-item-subtitle>
                    <v-list-item-subtitle>(933) 327 46 17</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </v-list>
              <div class="d-flex justify-center mt-5">
                <v-btn
                  v-for="(socialm, i) in social"
                  :key="`social-${i}`"
                  class="d-flex"
                  icon
                  large
                  color="partner"
                  :href="socialm.link"
                  target="_blank"
                >
                  <v-icon>{{ socialm.icon }}</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row></v-col
        >
        <v-col cols="1" class="text-center hidden-sm-and-down col col-2 py-12">
          <v-divider vertical></v-divider>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import logo from "@/components/Logo";

export default {
  components: {
    logo,
  },

  data() {
    return {
      social: [
        {
          platform: "Facebook",
          link: "",
          icon: "mdi-facebook",
        },
        {
          platform: "Instagram",
          link: "",
          icon: "mdi-instagram",
        },
        {
          platform: "Linkedin",
          link: "",
          icon: "mdi-linkedin",
        },
      ],
    };
  },
};
</script>
