import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    name: '404',
  },
  {
    path: "/loginAdmin",
    name: "Admin",
    component: () => import(/* webpackChunkName: "Home" */ "@/views/Login"),
  },
  {
    path: "/inscritos",
    name: "Inscritos",
    component: () => import(/* webpackChunkName: "Home" */ "@/views/Inscritos"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/inscribirse",
    name: "Inscribirse",
    component: () =>
      import(/* webpackChunkName: "About" */ "@/views/Inscribirse"),
  },
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "@/views/Home"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "About" */ "@/views/About"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "Contact" */ "@/views/Contact"),
  },
  {
    path: "/services",
    name: "Services",
    component: () =>
      import(/* webpackChunkName: "Services" */ "@/views/Services"),
  },
  {
    path: "/servicesJuridico",
    name: "ServicesJuridico",
    component: () =>
      import(
        /* webpackChunkName: "ServicesJuridico" */ "@/views/ServicesJuridico"
      ),
  },
  {
    path: "/servicesEmpresarial",
    name: "ServicesEmpresarial",
    component: () =>
      import(
        /* webpackChunkName: "ServicesEmpresarial" */ "@/views/ServicesEmpresarial"
      ),
  },
  {
    path: "/servicesLat",
    name: "ServicesLat",
    component: () =>
      import(/* webpackChunkName: "ServicesLat" */ "@/views/ServicesLat"),
  },
  {
    path: "/servicesSchool",
    name: "ServicesSchool",
    component: () =>
      import(/* webpackChunkName: "ServicesSchool" */ "@/views/ServicesSchool"),
  },
  {
    path: "/servicesSoftware",
    name: "ServicesSoftware",
    component: () =>
      import(
        /* webpackChunkName: "ServicesSoftware" */ "@/views/ServicesSoftware"
      ),
  },
];

function isObjEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj,prop)) return false;
  }
  return true;
}

// eslint-disable-next-line no-new
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((router) => router.meta.requiresAuth)) {
    if (isObjEmpty(store.state.datosUsuariosState)) {
      next("/loginAdmin");
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
