<template>
  <svg
    width="120"
    height="50"
    viewBox="0 0 120 50"
    fill="#FFF"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 4.84375 -16.796875 C 6.175781 -16.796875 7.164062 -16.441406 7.8125 -15.734375 C 8.457031 -15.035156 8.78125 -14.003906 8.78125 -12.640625 L 8.78125 -10.46875 C 8.78125 -9.101562 8.457031 -8.066406 7.8125 -7.359375 C 7.164062 -6.660156 6.175781 -6.3125 4.84375 -6.3125 L 3.59375 -6.3125 L 3.59375 0 L 0.953125 0 L 0.953125 -16.796875 Z M 3.59375 -14.40625 L 3.59375 -8.71875 L 4.84375 -8.71875 C 5.257812 -8.71875 5.578125 -8.828125 5.796875 -9.046875 C 6.023438 -9.273438 6.140625 -9.691406 6.140625 -10.296875 L 6.140625 -12.8125 C 6.140625 -13.425781 6.023438 -13.84375 5.796875 -14.0625 C 5.578125 -14.289062 5.257812 -14.40625 4.84375 -14.40625 Z M 3.59375 -14.40625"
      transform="translate(30.474 27.722)"
      :class="$vuetify.theme.dark ? 'fill-light-color' : 'fill-dark-color'"
    />

    <path
      d="M 9.5 0 L 6.84375 0 L 6.390625 -3.046875 L 3.140625 -3.046875 L 2.6875 0 L 0.265625 0 L 2.953125 -16.796875 L 6.8125 -16.796875 Z M 3.484375 -5.328125 L 6.03125 -5.328125 L 4.75 -13.828125 Z M 3.484375 -5.328125"
      transform="translate(39.64 27.722)"
      :class="$vuetify.theme.dark ? 'fill-light-color' : 'fill-dark-color'"
    />

    <path
      d="M 6.484375 0 C 6.453125 -0.113281 6.421875 -0.21875 6.390625 -0.3125 C 6.359375 -0.40625 6.328125 -0.523438 6.296875 -0.671875 C 6.273438 -0.816406 6.257812 -1 6.25 -1.21875 C 6.238281 -1.445312 6.234375 -1.726562 6.234375 -2.0625 L 6.234375 -4.703125 C 6.234375 -5.484375 6.097656 -6.035156 5.828125 -6.359375 C 5.554688 -6.679688 5.117188 -6.84375 4.515625 -6.84375 L 3.59375 -6.84375 L 3.59375 0 L 0.953125 0 L 0.953125 -16.796875 L 4.9375 -16.796875 C 6.320312 -16.796875 7.320312 -16.472656 7.9375 -15.828125 C 8.550781 -15.191406 8.859375 -14.226562 8.859375 -12.9375 L 8.859375 -11.609375 C 8.859375 -9.890625 8.28125 -8.753906 7.125 -8.203125 C 7.800781 -7.929688 8.257812 -7.488281 8.5 -6.875 C 8.75 -6.257812 8.875 -5.507812 8.875 -4.625 L 8.875 -2.046875 C 8.875 -1.628906 8.890625 -1.265625 8.921875 -0.953125 C 8.953125 -0.640625 9.035156 -0.320312 9.171875 0 Z M 3.59375 -14.40625 L 3.59375 -9.234375 L 4.625 -9.234375 C 5.125 -9.234375 5.515625 -9.363281 5.796875 -9.625 C 6.078125 -9.882812 6.21875 -10.347656 6.21875 -11.015625 L 6.21875 -12.671875 C 6.21875 -13.273438 6.109375 -13.710938 5.890625 -13.984375 C 5.671875 -14.265625 5.332031 -14.40625 4.875 -14.40625 Z M 3.59375 -14.40625"
      transform="translate(49.408 27.722)"
      :class="$vuetify.theme.dark ? 'fill-light-color' : 'fill-dark-color'"
    />
    <path
      d="M 0.1875 -16.796875 L 8.359375 -16.796875 L 8.359375 -14.40625 L 5.59375 -14.40625 L 5.59375 0 L 2.953125 0 L 2.953125 -14.40625 L 0.1875 -14.40625 Z M 0.1875 -16.796875"
      transform="translate(59.055 27.722)"
      :class="$vuetify.theme.dark ? 'fill-light-color' : 'fill-dark-color'"
    />
    <path
      d="M 3.3125 -12.171875 L 3.3125 0 L 0.9375 0 L 0.9375 -16.796875 L 4.25 -16.796875 L 6.953125 -6.75 L 6.953125 -16.796875 L 9.3125 -16.796875 L 9.3125 0 L 6.59375 0 Z M 3.3125 -12.171875"
      transform="translate(67.598 27.722)"
      :class="$vuetify.theme.dark ? 'fill-light-color' : 'fill-dark-color'"
    />
    <path
      d="M 3.59375 -9.71875 L 7.21875 -9.71875 L 7.21875 -7.3125 L 3.59375 -7.3125 L 3.59375 -2.40625 L 8.15625 -2.40625 L 8.15625 0 L 0.953125 0 L 0.953125 -16.796875 L 8.15625 -16.796875 L 8.15625 -14.40625 L 3.59375 -14.40625 Z M 3.59375 -9.71875"
      transform="translate(77.844 27.722)"
      :class="$vuetify.theme.dark ? 'fill-light-color' : 'fill-dark-color'"
    />
    <path
      d="M 6.484375 0 C 6.453125 -0.113281 6.421875 -0.21875 6.390625 -0.3125 C 6.359375 -0.40625 6.328125 -0.523438 6.296875 -0.671875 C 6.273438 -0.816406 6.257812 -1 6.25 -1.21875 C 6.238281 -1.445312 6.234375 -1.726562 6.234375 -2.0625 L 6.234375 -4.703125 C 6.234375 -5.484375 6.097656 -6.035156 5.828125 -6.359375 C 5.554688 -6.679688 5.117188 -6.84375 4.515625 -6.84375 L 3.59375 -6.84375 L 3.59375 0 L 0.953125 0 L 0.953125 -16.796875 L 4.9375 -16.796875 C 6.320312 -16.796875 7.320312 -16.472656 7.9375 -15.828125 C 8.550781 -15.191406 8.859375 -14.226562 8.859375 -12.9375 L 8.859375 -11.609375 C 8.859375 -9.890625 8.28125 -8.753906 7.125 -8.203125 C 7.800781 -7.929688 8.257812 -7.488281 8.5 -6.875 C 8.75 -6.257812 8.875 -5.507812 8.875 -4.625 L 8.875 -2.046875 C 8.875 -1.628906 8.890625 -1.265625 8.921875 -0.953125 C 8.953125 -0.640625 9.035156 -0.320312 9.171875 0 Z M 3.59375 -14.40625 L 3.59375 -9.234375 L 4.625 -9.234375 C 5.125 -9.234375 5.515625 -9.363281 5.796875 -9.625 C 6.078125 -9.882812 6.21875 -10.347656 6.21875 -11.015625 L 6.21875 -12.671875 C 6.21875 -13.273438 6.109375 -13.710938 5.890625 -13.984375 C 5.671875 -14.265625 5.332031 -14.40625 4.875 -14.40625 Z M 3.59375 -14.40625"
      transform="translate(86.675 27.722)"
      :class="$vuetify.theme.dark ? 'fill-light-color' : 'fill-dark-color'"
    />
    <path
      d="M 0.625 -12.765625 C 0.625 -14.128906 0.945312 -15.171875 1.59375 -15.890625 C 2.238281 -16.617188 3.210938 -16.984375 4.515625 -16.984375 C 5.804688 -16.984375 6.773438 -16.617188 7.421875 -15.890625 C 8.078125 -15.171875 8.40625 -14.128906 8.40625 -12.765625 L 8.40625 -12.234375 L 5.90625 -12.234375 L 5.90625 -12.9375 C 5.90625 -13.539062 5.785156 -13.96875 5.546875 -14.21875 C 5.316406 -14.46875 4.992188 -14.59375 4.578125 -14.59375 C 4.160156 -14.59375 3.835938 -14.46875 3.609375 -14.21875 C 3.378906 -13.96875 3.265625 -13.539062 3.265625 -12.9375 C 3.265625 -12.363281 3.390625 -11.851562 3.640625 -11.40625 C 3.898438 -10.96875 4.222656 -10.550781 4.609375 -10.15625 C 4.992188 -9.769531 5.40625 -9.378906 5.84375 -8.984375 C 6.28125 -8.597656 6.691406 -8.171875 7.078125 -7.703125 C 7.460938 -7.242188 7.78125 -6.71875 8.03125 -6.125 C 8.289062 -5.53125 8.421875 -4.832031 8.421875 -4.03125 C 8.421875 -2.664062 8.085938 -1.617188 7.421875 -0.890625 C 6.765625 -0.171875 5.785156 0.1875 4.484375 0.1875 C 3.191406 0.1875 2.210938 -0.171875 1.546875 -0.890625 C 0.878906 -1.617188 0.546875 -2.664062 0.546875 -4.03125 L 0.546875 -5.0625 L 3.046875 -5.0625 L 3.046875 -3.859375 C 3.046875 -3.253906 3.171875 -2.832031 3.421875 -2.59375 C 3.671875 -2.351562 4.003906 -2.234375 4.421875 -2.234375 C 4.835938 -2.234375 5.164062 -2.351562 5.40625 -2.59375 C 5.65625 -2.832031 5.78125 -3.253906 5.78125 -3.859375 C 5.78125 -4.441406 5.648438 -4.953125 5.390625 -5.390625 C 5.140625 -5.828125 4.820312 -6.238281 4.4375 -6.625 C 4.050781 -7.019531 3.640625 -7.414062 3.203125 -7.8125 C 2.765625 -8.207031 2.351562 -8.632812 1.96875 -9.09375 C 1.582031 -9.5625 1.257812 -10.085938 1 -10.671875 C 0.75 -11.265625 0.625 -11.960938 0.625 -12.765625 Z M 0.625 -12.765625"
      transform="translate(96.322 27.722)"
      fill="#004AAD"
    />
    <path
      d="M 4 -10.796875 L 8.015625 -10.796875 L 8.015625 -8.125 L 4 -8.125 L 4 -2.671875 L 9.0625 -2.671875 L 9.0625 0 L 1.0625 0 L 1.0625 -18.65625 L 9.0625 -18.65625 L 9.0625 -15.984375 L 4 -15.984375 Z M 4 -10.796875"
      transform="translate(11.777 28.816)"
      fill="#004AAD"
    />
    <path
      d="M 0.265625 -2.671875 C 0.421875 -2.648438 0.585938 -2.640625 0.765625 -2.640625 C 0.941406 -2.640625 1.113281 -2.640625 1.28125 -2.640625 C 1.738281 -2.640625 2.128906 -2.753906 2.453125 -2.984375 C 2.785156 -3.210938 2.953125 -3.691406 2.953125 -4.421875 L 2.953125 -18.65625 L 5.890625 -18.65625 L 5.890625 -4.640625 C 5.890625 -3.691406 5.769531 -2.914062 5.53125 -2.3125 C 5.300781 -1.707031 4.988281 -1.234375 4.59375 -0.890625 C 4.207031 -0.546875 3.757812 -0.300781 3.25 -0.15625 C 2.75 -0.0195312 2.222656 0.046875 1.671875 0.046875 C 1.410156 0.046875 1.171875 0.046875 0.953125 0.046875 C 0.742188 0.046875 0.515625 0.03125 0.265625 0 Z M 0.265625 -2.671875"
      transform="translate(21.582 28.816)"
      :class="$vuetify.theme.dark ? 'fill-light-color' : 'fill-dark-color'"
    />
  </svg>
</template>

<script>
export default {

  name: "logo",

};
</script>

<style scoped>
.fill-theme-color {
  fill: #1976d2;
}
.fill-light-color {
  fill: #fff;
}
.fill-dark-color {
  fill: #000;
}
</style>
